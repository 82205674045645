// External Packages
import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";

// Internal Packages
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";

// External Components
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";

// Internal Components

import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import SearchBar from "../UI/SearchBar";
import Heading from "../UI/Heading";
import LocationCard from "../locations-page/LocationCard";
import { requestAdminAPI } from "../../api/posh-api";

/*
  * All Customer Dues  Page  
    ************************************* Working  on it not completed
  This page displays all Dues For  All Customer the database.
*/

export default function Dues() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  //Load Context
  const { loadingData, fetchCustomers, locations } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  const defaultFilter = {
    search: params.get("search") ?? "",
    location: params.get("location") ?? "All",
    booking: params.get("booking") ?? "All",
    plan: params.get("plan") ?? "All",
  };

  const [filteredLocations, setFilteredLocations] = useState(locations);

  const [filter, setFilter] = useState({ ...defaultFilter });

  /* ======================= APPLY FILTER LOCATIONS ======================= */
  const applyFilters = () => {
    let records = [...locations];
    // Apply Tab selection filter
    records = tabsFilter(records);
    //Apply search filter
    if (filter.search) {
      records = searchLocations(records);
    }
    setFilteredLocations([...(records ?? [])]);
  };

  // Filter Dropdown options
  const tabsFilter = (data) => {
    let records = [...data];
    const filtered = [];
    records.forEach((customer) => {
      let flag = true;
      if (
        filter.location !== "All" &&
        customer.state?.toUpperCase() !==
          filter.location.split(",")[1].trim().toUpperCase()
      )
        flag = false;
      if (filter.booking === "Booked" && !customer.booking) flag = false;
      if (filter.booking === "Not Booked" && customer.booking) flag = false;
      if (filter.plan !== "All" && customer.plan?.name !== filter.plan)
        flag = false;
      if (flag) filtered.push(customer);
    });
    return [...filtered];
  };

  // ========================================================== SEARCH LOCATIONS
  const searchLocations = (records) => {
    return records.filter((location) => {
      const string = `${location.id} ${location.address}
        ${location.tax} ${location.code}`.toLowerCase();
      return string.includes(filter.search.toLowerCase());
    });
  };

  // Fetch LOCATIONS
  useEffect(() => {
    applyFilters();
  }, [filter]);

  // Clear Search Filter
  const clearSearch = () => {
    setFilter({ ...filter, search: "" });
    navigate(`/locations`, {}, { replace: true });
  };
  // Search handler
  const searchHandler = (value) => {
    const { location, booking, plan } = filter;
    navigate(`/locations?search=${value}`, {}, { replace: true });

    setFilter({ ...filter, search: value });
  };

  return (
    <div className="container grid">
      <Heading title="Dues" isBackArrow={false}>
        <BsPlusLg
          onClick={(e) => navigate("/locations/add", e)}
          className="text-lg text-white cursor-pointer hover:text-stone-400"
        />
        <MdRefresh
          onClick={fetchCustomers}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
      </Heading>

      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearch}
        searchValue={filter.search}
      />

      <div className="list">
        {loadingData ? (
          <LoadingSpinnerWrapper />
        ) : (
          <div className="grid gap-8">
            {filteredLocations.map((location) => (
              <LocationCard location={location} key={location.id} />
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center justify-end space-x-4 text-sm text-stone-200">
        <p className="text-stone-400">
          Showing {filteredLocations.length} of {locations.length}{" "}
        </p>
        <BsArrowLeftSquare className="inline-block text-xl" />
        <BsArrowRightSquare className="inline-block text-xl" />
      </div>
    </div>
  );
}
