import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import SidebarButton from "../UI/SidebarButton";
import { useLocation } from "react-router-dom";
import { toTitleCase } from "../../util/toTitleCase";
import { FaUserCircle } from "react-icons/fa";

export default function Sidebar() {
  const location = useLocation();
  const { navigate } = useContext(UtilityContext);

  const { user } = useContext(AuthContext);
  const { customers } = useContext(DataContext);
  const [admin, setAdmin] = useState(false);

  // Get page address from url
  const selected = toTitleCase(location.pathname.slice(1).split("/")[0]);

  const selectionHandler = (selected, e) => {
    navigate(`/${selected.toLowerCase()}`, e);
  };

  useEffect(() => {
    setAdmin(customers.filter((c) => c.id === user.uid)[0]);
  }, [customers]);

  return (
    <div className="relative hidden w-full px-6 py-12 bg-stone-900 rounded-xl md:block">
      <div className="flex flex-col items-center space-y-2">
        {admin?.photo ? (
          <img src={admin.photo} className="w-16 h-16 rounded-full" />
        ) : (
          <FaUserCircle className="w-16 h-16 text-yellow-600" />
        )}
        {admin ? (
          <p className="text-stone-300">
            {admin.firstName} {admin.lastName}
          </p>
        ) : (
          <p className="text-stone-300">{user.displayName}</p>
        )}
      </div>
      <div className="grid gap-8 mt-16 mb-6">
        <SidebarButton
          title="General"
          selected={selected.length === 0 ? "General" : selected}
          onClick={(e) => selectionHandler("General", e)}
        />
        <SidebarButton
          title="Bookings"
          selected={selected}
          onClick={(e) => selectionHandler("Bookings", e)}
        />
        <SidebarButton
          title="Customers"
          selected={selected}
          onClick={(e) => selectionHandler("Customers", e)}
        />
        <SidebarButton
          title="Partners"
          selected={selected}
          onClick={(e) => selectionHandler("Partners", e)}
        />
        <SidebarButton
          title="Vehicles"
          selected={selected}
          onClick={(e) => selectionHandler("Vehicles", e)}
        />
        <SidebarButton
          title="Payments"
          selected={selected}
          onClick={(e) => selectionHandler("Payments", e)}
        />
        <SidebarButton
          title="Coupons"
          selected={selected}
          onClick={(e) => selectionHandler("Coupons", e)}
        />

        <SidebarButton
          title="Tolls"
          selected={selected}
          onClick={(e) => selectionHandler("Tolls", e)}
        />
        <SidebarButton
          title="Charging"
          selected={selected}
          onClick={(e) => selectionHandler("Charging", e)}
        />
        <SidebarButton
          title="Expenses"
          selected={selected}
          onClick={(e) => selectionHandler("Expenses", e)}
        />
        <SidebarButton
          title="Locations"
          selected={selected}
          onClick={(e) => selectionHandler("Locations", e)}
        />
        {/* <SidebarButton
          title="Dues"
          selected={selected}
          onClick={(e) => selectionHandler("Dues", e)}
        /> */}
      </div>
    </div>
  );
}
