// External Packages
import { useState, useContext } from "react";
import InputMask from "react-input-mask";

// Internal Packages
import { DataContext } from "../../context/data-context";
import { AuthContext } from "../../context/auth-context";
import { UtilityContext } from "../../context/util-context";
import { addLocation } from "../../api/add";

// Internal Components
import Heading from "../UI/Heading";
import LoadingSpinner from "../UI/LoadingSpinner";

/** ========================================================================
 * * Add Location Page
 * Renders a page, which allows adding a new lcoations to the database.
 * ========================================================================== */

export default function AddLocation() {
  // Load Contexts
  const { user } = useContext(AuthContext);
  const { fetchLocations } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize Processing States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();

  const [phone, setPhone] = useState("+1 ");

  const handlePhoneChange = (event) => {
    const value = event.target.value;

    // Prevent clearing of +1
    if (!value.startsWith("+1 ")) {
      setPhone("+1 ");
    } else {
      setPhone(value);
    }
  };

  // ==================================== SUBMIT HANDLER
  const submitHandler = async (event) => {
    try {
      event.preventDefault();
      // Clear any previous warnings
      setWarning();
      setLoading(true);

      // Create a payload for all location information
      const payload = {
        name: event.target.name.value,
        address: event.target.address.value,
        tax: event.target.tax.value,
        miles: {
          monthly: event.target.monthly_miles.value,
          weekly: event.target.weekly_miles.value,
        },
        phone: event.target.phone.value,
        code: event.target.code.value,
        delivery: {
          rate: event.target.delivery_rate.value,
        },
      };

      // Add the Location to the database
      const response = await addLocation(user, payload);
      // Fetch the Location again to update the list
      fetchLocations(() => {
        navigate(`/locations/info?id=${response}`);
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="container grid">
      <Heading title="Add Location" />
      <form onSubmit={submitHandler} className="grid gap-8">
        <div className="grid text-sm md:grid-cols-4 gap-x-8 gap-y-4 text-stone-400 md:grid">
          <input className="hidden" name="id" disabled required />
          <div className="grid">
            <label className="text-sm">Name:</label>
            <input className="form-input" name="name" required />
          </div>
          <div className="grid">
            <label className="text-sm"> ADDRESS:</label>
            <input className="form-input" name="address" required />
          </div>

          <div className="grid">
            <label className="text-sm">TAX(%):</label>
            <input className="form-input" type="number" name="tax" required />
          </div>
          <div className="grid">
            <label className="text-sm">DELIVERY RATE:</label>
            <input
              className="form-input"
              type="number"
              name="delivery_rate"
              required
            />
          </div>
          <div className="grid">
            <label className="text-sm">WEEKLY MILES:</label>
            <input
              className="form-input"
              type="number"
              name="weekly_miles"
              required
            />
          </div>
          <div className="grid">
            <label className="text-sm">MONTHLYLY MILES:</label>
            <input
              className="form-input"
              type="number"
              name="monthly_miles"
              required
            />
          </div>
          <div className="grid">
            <label className="text-sm">CODE :</label>
            <input className="form-input" name="code" required />
          </div>
          <div className="grid">
            <label className="text-sm">PHONE:</label>
            <InputMask
              mask="+1 999 999 9999"
              className="form-input"
              value={phone}
              onChange={handlePhoneChange}
              type="tel"
              name="phone"
              required
            />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <button className="w-full btn-primary md:w-fit">Submit</button>
          )}
          {warning && <p className="text-red-600">{warning}</p>}
        </div>
      </form>
    </div>
  );
}
